[class*="status"] {
  border-radius: 6px;
  text-align: center;
  padding: 2px 0px;
  font-weight: bold;
  font-size: 0.9em;
  width: 100%;
}

.statusNew {
  background-color: #00ff80;
  color: #006633;
}

.statusApproved {
  background-color: #ffe433;
  color: #665800;
}

.statusExported {
  background-color: #ff99cc;
  color: #660033;
}

.filterSelect {
  height: 2.4em;
  border-radius: 6px;
  padding: 0 10px;
}

.gridButton {
  font-size: 1em !important;
  text-transform: none !important;
}
