body {
}

.gridButton {
  font-size: 1em !important;
  text-transform: none !important;
}

.tabButton {
  font-size: 1em !important;
  text-transform: none !important;
  align-items: start !important;
  padding-left: 10px !important;
}

.deleteButton {
  display: none !important;
}

  .card:hover + .deleteButton, .deleteButton:hover {
    display: inline-block !important;
  }
