.select {
  height: 2.4em;
  border-radius: 6px;
  padding: 0 10px;
}

[class*="userStatus"] {
  border-radius: 6px;
  text-align: center;
  padding: 2px 8px;
  font-weight: bold;
  font-size: 0.9em;
  width: 100%;
}

.userStatusAccess {
  background-color: #00ff80;
  color: #006633;
}

.userStatusSysAdmin {
  background-color: #ffe433;
  color: #665800;
}

.userStatusNoAccess {
  background-color: #ff99cc;
  color: #660033;
}

.tableHeader th {
  font-weight: 400;
}

.tableBody td {
  vertical-align: middle;
}

.gridButton {
  font-size: 1em !important;
  text-transform: none !important;
}

