body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

*/
/* 
 * This <p> style is here to override the "margin-bottom: 1rem" coming from bootstrap/scss/_reboot.scss. Without this the "Rows per page" footer
 * in the @mui/DataGrid is misaligned with other elements
*/
p {
  margin-bottom: 0;
}

/* 
  This style fixes a problem with MUI Select and TextField components (introduced by bootstrap/scss/_reboot.scss) in which the top border
  is missing.  This is caused by an empty <legend> having a width of 100%
*/
legend {
  width: auto !important;
}
/* If other problems occur, may want to consider using a named style instead, like below, and use it thus:  <Select className={'muiHideEmptyLegend'} ...> */
/*
.muiHideEmptyLegend legend {
  width: auto;
}
*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.pageHeader {
  font-size: 18px;
  font-weight: 500;
  margin: 8px 0;
}

.dialogHeader {
  font-size: 16px;
  font-weight: 500;
  margin: 8px 0;
}

.gridButton {
  font-size: 1em !important;
  text-transform: none !important;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
}

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  *::-webkit-scrollbar-track {
    background: #f8f8f8;
    border-radius: 20px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #c0c0c0;
    border-radius: 20px;
    border: 3px solid #f8f8f8;
  }

@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;700&display=swap');
