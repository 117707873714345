.mainDiv {
  background-color: #2E4737;
  margin: 0px;
  height: 100vh;
}

.titleDiv {
  background-color: #2E4737;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.navbarColour {
  background-color: #2E4737;
}

.title {
  color: white;
  font-family: 'Merriweather', Times, serif;
  font-style: italic;
  font-weight: 700;
  font-size: 50px;
}

.subtitle {
  color: white;
  font-family: 'Merriweather', Times, serif;
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
}
